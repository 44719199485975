import React, { Component } from 'react';
import { FaSyncAlt } from 'react-icons/fa';

import Branding from './Branding';

class Home extends Component {

    componentDidMount() {
        this.props.setStyles();
    }


    render() {

        const {colors, languages, languageSelect, homeMessage} = this.props;

        const colorFront    = {
            color: colors.colorFront,
            textShadow: '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency,
            whiteSpace: 'pre-wrap'
        }

        const BtnLarge = {
            color: colors.colorBtnText,
            backgroundColor: colors.colorBack + colors.buttonTransparency,
        }

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
            whiteSpace: 'pre-wrap'
        }

        const loaderColors = {
            border: '4px solid ' + colors.colorFront,
            borderColor: colors.colorFront + ' transparent transparent transparent',
        }


        return (

            <div>
                <div className="container-fluid d-flex min-vh-100 align-items-center">

                    <div className="row main-content d-flex justify-content-center vw-100">

                        {this.props.step === 2 && (
                            <div id="loading" className="lds-ring">
                                <div style={ loaderColors }></div>
                                <div style={ loaderColors }></div>
                                <div style={ loaderColors }></div>
                                <div style={ loaderColors }></div>
                            </div>
                        )}

                        {(this.props.step === 1 && homeMessage !=="") &&
                            <div className="content col-12 col col-sm-10 col-lg-6 text-center" onClick={() => this.props.clearHomeMessage()}>
                                <h3><div id="home-message"  style={colorPop} dangerouslySetInnerHTML={{__html: homeMessage}}></div></h3>
                            </div>

                        }

                        {(this.props.step === 1 && homeMessage === "") &&
                            <div className="content text-center">
                                <div>
                                   <h1 style={ colorFront }>{this.props.t("#WELCOME#")}</h1>
                                </div>

                                <div className="p-4">

                                    <button id="reload" className="btn-reload p-4" style={BtnLarge} type="button" onClick={() => this.props.loadClientValues()}>
                                        <FaSyncAlt/>
                                    </button>

                                </div>
                            </div>
                        }
                    </div>

               </div>


               <Branding
                   colorFront={colorFront}
                   languages={languages}
                   languageSelect={languageSelect}
                   changeLanguage={this.props.changeLanguage.bind(this)}
                   t={this.props.t.bind(this)}
                   />

           </div>

        );
    }
}

export default Home;
