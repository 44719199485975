import React, { Component } from 'react';
import logoVisitors from './images/logo.png';

class SplashScreen extends Component {

    render() {

        const { background, splashLogo } = this.props;

        let logo = logoVisitors;

        if (splashLogo !== '') {
            logo = "data:image/png;base64, " + splashLogo;
        }

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        return (

            <div id="app-window" style={ background }>
                <div className="container-fluid d-flex full-height align-items-center">
                    <div className="row d-flex justify-content-center vw-100">
                       <div className="content col-12 text-center">
                           <div id="logo-wrapper">
                            <img id="logo" src={logo} alt="Logo" />
                          </div>
                       </div>
                   </div>
               </div>
            </div>
        );
    }
}

export default SplashScreen;