import React, { Component } from 'react';
import logoVisitors from './images/logo.png';

class ErrorPage extends Component {

    render() {

        const { colors, background, splashLogo, languages} = this.props;

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
            whiteSpace: "pre-wrap"
        }

        let logo = logoVisitors;

        if (splashLogo !== '') {
            logo = "data:image/png;base64, " + splashLogo;
        }

        var message = "#NOINVITATION#";

        if(languages.length === 0) {
            message = "No invitation found";
        }


        return (

            <div id="app-window" className="visitors-background" style={background}>
                <div className="container-fluid d-flex full-height align-items-center">

                    <div className="row d-flex justify-content-center vw-100">
                       <div id="error" className="content col-10 col-lg-6" style={colorPop}>
                           <strong>{this.props.t(message)}</strong>
                       </div>
                   </div>

                    <div className="content col-12 fixed-bottom mb-5 text-center">
                        <div id="logo-wrapper">
                            <img id="logo" src={logo} alt="Logo" />
                        </div>

                    </div>

               </div>
            </div>
        );
    }
}

export default ErrorPage;