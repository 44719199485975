import React, {Component} from 'react';
import { IoMdLogOut } from 'react-icons/io';
import Branding from './Branding';

class CheckOutConfirm extends Component {

    setCheckInStyle = () => {
        this.props.setStyles();
    };

    componentDidMount() {

        this.props.setHomeMessage('');

        this.props.setStyles();

        window.addEventListener('resize', this.setCheckInStyle);

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;

    }

    componentWillUnmount() {

        window.removeEventListener('resize', this.setCheckInStyle)

    }


    render () {

        const {colors, background, languages, languageSelect, mainStyle} = this.props;

        const colorFront    = {
            color: colors.colorFront,
            textShadow:  '0px 4px 3px ' + colors.colorFrontShadow + colors.colorFrontShadowTransparency,
            whiteSpace: 'pre-wrap'
        }

        const BtnLarge = {
            color: colors.colorBtnText,
            backgroundColor: colors.colorBack + colors.buttonTransparency,
        }

        const colorPop = {
            color: colors.colorPopText,
            backgroundColor: colors.colorPopBack + colors.colorPopTransparency,
            whiteSpace: "pre-wrap"
        }

        const loaderColors = {
            border: '4px solid ' + colors.colorFront,
            borderColor: colors.colorFront + ' transparent transparent transparent',
        }

        const { guestName } = this.props;

        return (

            <div id="app-window" style={ background } className="full-height">
                <div className="container-fluid d-flex align-items-center">
                    <div className="row d-flex justify-content-center vw-100">
                        <div className="content col-12 col-lg-6">

                            {this.props.step === 2 && (
                                <div className="container-fluid d-flex full-height align-items-center">
                                    <div className="row d-flex justify-content-center vw-100">
                                        <div id="loading" className="lds-ring">
                                            <div style={ loaderColors }></div>
                                            <div style={ loaderColors }></div>
                                            <div style={ loaderColors }></div>
                                            <div style={ loaderColors }></div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {this.props.step === 1 && (

                                <div id="confirm" className="main-content" style={Object.assign({}, mainStyle, colorPop)}>

                                    <h3 id="visitor-name" style={colorFront}>
                                        { guestName }
                                    </h3>

                                    <button className="btn-large p-4" type="button" style={BtnLarge} onClick={() => this.props.updateGuest("check-out")}>
                                        {this.props.t('Check Out')} <IoMdLogOut/>
                                    </button>

                                </div>

                            )}
                        </div>
                    </div>
                </div>
                <Branding
                    colorFront={colorFront}
                    languages={languages}
                    languageSelect={languageSelect}
                    changeLanguage={this.props.changeLanguage.bind(this)}
                    t={this.props.t.bind(this)}
                    />
            </div>
        )
    }
}

export default CheckOutConfirm;