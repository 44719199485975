import React, {Component} from 'react';
import axios from "axios";

import SplashScreen from './SplashScreen';
import Home from './Home';
import ErrorPage from './ErrorPage';
import CheckInConfirm from './CheckInConfirm';
import CheckOutConfirm from './CheckOutConfirm';

import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';
import YouTube from 'react-youtube';

import FreeVersionBadge from './images/free-version-badge.jpg';
import FreeSMSTrialBadge from './images/free-sms-trial-badge.jpg';

// domain: 'https://dev-visitorsfortrello.visitorsfortrello.com',
// domain: 'https://visitorsfortrello.com',

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'https://visitorsfortrello.com',
            displaySettings: [],
            colors: {
                colorFront: '',
                colorFrontShadow: '',
                colorFrontShadowTransparency: '',
                colorBtnText: '',
                colorBack: '',
                colorPopText: '',
                colorPopBack: '',
                colorPopTransparency: '',
                colorInputBorder: '',
                colorInputTransparency: '',
            },
            background: {},
            backgroundType: '',
            backgroundColor: '',
            backgroundImage: '',
            updated: '',
            fullContent: true,
            host: '',
            translations: [],
            languages: [],
            languageSelect: 0,
            languageDefault: '',
            homeMessage: '',
            terms: '',
            phoneCodes: [],
            guestName: '',
            accessKey: '0',
            step: 1,
            splashLogo: '',
            screen: '',
            mainStyle: {},
            hasAccount: 1,
            freeSMSPoints: 0,
            shouldPauseVideo: true,

        };

        this.youtubeRef = React.createRef();

    }

    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    componentDidMount() {
        this.loadClientValues();
    }

    setStyles(){

        let h  = window.innerHeight

        var mainStyle;

        const element = document.getElementsByClassName('main-content')[0];

        if(typeof(element) != 'undefined' && element != null){

            var offsetHeight    = element.offsetHeight;

            const back      = document.getElementById("back");
            const branding  = document.getElementById("branding");

            var marginTop = (h-offsetHeight)/2;

            // Gør plads til FREE banner
            if(this.state.hasAccount === 1 && this.state.freeSMSPoints === "50" && marginTop < 170){
                marginTop = 170;
            }

            if(marginTop < 40) {
                marginTop = 40;
            }

            if(offsetHeight + marginTop + 75 > h){

                if(typeof(back) != 'undefined' && back != null){
                    back.style.setProperty('position', 'relative');
                }

                if(typeof(branding) != 'undefined' && branding != null) {
                    branding.style.setProperty('position', 'initial');
                    //branding.style.setProperty('margin-bottom', '25px');
                }


            } else {


                if(typeof(back) != 'undefined' && back != null){
                    back.style.setProperty('position', 'absolute');
                }

                if(typeof(branding) != 'undefined' && branding != null) {
                    branding.style.setProperty('position', 'absolute');
                    branding.style.setProperty('bottom', '0px');
                }

            }

            mainStyle = {
                marginTop: marginTop + 'px',
                marginBottom: '25px',
            }

            this.setState({ mainStyle: mainStyle });

            const appStyle = {
                minHeight: (offsetHeight + marginTop) + 'px'
            }

            this.setState({ appStyle: appStyle });

            var timer = setInterval(function(){
                this.toggleBranding();
                clearInterval(timer);
            }.bind(this),500);

        }
    }

    toggleBranding(){

        const branding      = document.getElementById('branding');
        const brandLabel    = document.getElementById("brand-label");

        if(typeof(brandLabel) != 'undefined' && brandLabel != null){

            const element               = document.getElementsByClassName('main-content')[0];
            var offsetWidth             = element.offsetWidth;
            const brandLabelTextWidth   = document.getElementById("brand-text").clientWidth+60;

            const info                  = document.getElementById("info");

            if(typeof(info) != 'undefined' && info != null){
                offsetWidth = offsetWidth - info.clientWidth;
            }

            const language              = document.getElementById("language");
            if(typeof(language) != 'undefined' && language != null){
                offsetWidth = offsetWidth - language.clientWidth;
            }

            if(offsetWidth - brandLabelTextWidth < 15){
                document.getElementById("brand-text").classList.add('not-visible');
            } else {
                document.getElementById("brand-text").classList.remove('not-visible');
            }

        }

        branding.classList.remove("transparent");

    }



loadClientValues(){

        var accessKey       =  this.getParameterByName('k');
        const accessKeyOld  =  this.getParameterByName('accessKey');

        if(accessKey == null) {
            accessKey = accessKeyOld;
        }

        this.setState({ accessKey: accessKey});

        const data = {
            accessKey: accessKey,
        };

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php?action=connectInvitation',
            data: data

        }).then((response) => {

            const data = response.data;

            if(Object.keys(data).length > 0) {

                this.setState({ splashLogo: data.client.account.splash_logo });

                this.changeScreen('splashscreen');

                localStorage.setItem('displaySettings', JSON.stringify(data.client));

                this.setState({ guestId: data.card.id});

                this.setResponseStates(data.client);

                this.splashscreenWait = setTimeout(function () {

                    this.setState({step: 1});

                    const visitorCheckedIn      = data.card.visitor_checked_in;

                    if(visitorCheckedIn === false) {

                        this.setState({ guestName: data.card.name });
                        this.changeScreen('check-in-confirm');

                    } else if(visitorCheckedIn === true) {

                        this.setState({ guestName: data.card.name });
                        this.changeScreen('check-out-confirm');

                    } else {
                        this.changeScreen('error-page');

                    }

                }.bind(this), 2000);

            } else {

                this.changeScreen('error-page');

            }

        }).catch((error) => {

            console.log(error)

        });

    }

    setResponseStates(response){

        this.setState({ colors: {
            colorFront: response.color_front,
            colorFrontShadow: response.color_front_shadow,
            colorFrontShadowTransparency: response.color_front_shadow_transparency,
            colorBtnText: response.color_btn_text,
            colorBack: response.color_back,
            buttonTransparency: response.button_transparency,
            colorPopText: response.color_pop_text,
            colorPopBack: response.color_pop_back,
            colorPopTransparency: response.color_pop_transparency,
            colorInputBorder: response.color_input_border,
            colorInputBorderTransparency: response.color_input_border_transparency,
            }
        });

        this.setState({ terms: response.terms});

        const backgroundType        = Number(response.background_type);

        let backgroundColor         = '#000';
        let backgroundImage         = '';
        let backgroundBlendMode     = '';

        if(backgroundType === -1) {
            backgroundImage         = this.state.domain + "/backgrounds/visitors-background.jpg";
            backgroundBlendMode     = "normal";

        } else if(backgroundType === -2) {
            backgroundImage         = "data:image/png;base64," + response.background_image;
            backgroundBlendMode     = "normal";

        } else if(backgroundType === -3) {
            backgroundColor         = response.background_color;

        } else if(backgroundType >= 1 && backgroundType < 99) {
            backgroundColor         = response.background_color;
            backgroundImage         = this.state.domain + "/backgrounds/texture_" + backgroundType + ".png";
            backgroundBlendMode     = "overlay";

        } else if(backgroundType >= 100 && backgroundType < 199) {
            backgroundColor         = response.background_color;
            backgroundImage         = this.state.domain + "/backgrounds/texture_" + backgroundType + ".png";
            backgroundBlendMode     = "multiply";

        }

        if(backgroundType === 3) {
            document.body.style.backgroundColor = backgroundColor;
        } else {

        }
        this.onBackgroundImageLoaded(backgroundImage, backgroundColor, backgroundBlendMode);

        this.setState({ hasAccount: response.has_account });
        this.setState({ freeSMSPoints: response.free_sms_points });

        this.setState({ translations: response.translations });
        this.setState({ languages: response.languages });
        this.setState({ languageSelect: response.language_select });
        this.setState({ languageDefault: response.language_default });

        this.setState({ phoneCodes: response.phone_codes });

        this.setState({ updated: response.updated });

    }

    setBackground (background, color, mode) {
        background = {
            backgroundImage: "url(" + background + ")",
            backgroundBlendMode: mode,
            backgroundSize: 'cover',
            backgroundColor: color,
            minHeight: '100vh',
        }
        this.setState({ background: background});
        document.body.style.backgroundColor = color;
    }

    onBackgroundImageLoaded (url, color, mode) {
        const img = new Image();
        img.src = url;
        img.onloadend = this.setBackground(img.src, color, mode);  //Image has loaded or failed
        return;
    }

    updateGuest(status) {

        this.setState({step: 2});

        const data = JSON.parse(localStorage.getItem('displaySettings'));
        data.cardId = this.state.guestId;

        var text;

        if(status === "check-in") {
            data.idList = data.check_in_list_id;
            data.in_out = 'in';
            text = this.t('#CHECKIN#').replace('#NAME#', this.state.guestName);

        } else if(status === "check-out") {
            data.idList = data.check_out_list_id;
            data.in_out = 'out';
            text = this.t('#CHECKOUT#').replace('#NAME#', this.state.guestName);
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php?action=updateVisitor',
            data: data

        }).then((responseObject) => {

            this.setHomeMessage(text);

            this.changeScreen('home');


        }).catch((error) => {

            console.log(error)

        });

    }

    t( string ){

        if( this.state.translations !== null && this.state.translations[ string ] !== undefined  && this.state.translations[ string ] !== "") {
            return this.convertText(decodeURIComponent(escape(this.state.translations[ string ])));
        } else {
            return string;
        }
    }

    changeLanguage(locale) {

        const data = JSON.parse(localStorage.getItem('displaySettings'));

        const dataSend = {
            locale: locale,
            client_id: data.id,
            organization_id: data.organization_id
        }

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php?action=getTranslations',
            data: dataSend

        }).then((response) => {

            this.setState({ languageDefault: locale });
            this.setState({ translations: response.data });

            this.setStyles();

        });

    }

    setHomeMessage(message) {

        this.setState({step: 1});
        this.setState({ homeMessage: message });

        clearInterval(this.messageTimer);
        this.messageTimer = setInterval(clearHomeMessage.bind(this), 7000);

        function clearHomeMessage() {
            this.setState({ homeMessage: '' });
            clearInterval(this.messageTimer);
        }

    }

    clearHomeMessage() {
        this.setState({ homeMessage: '' });
    }

    changeScreen(screen) {
        this.setState({ screen: screen});
    }

    convertText(text){

        text = text.split("\n").join("<br>");

        let strong = 0;
        let output = '';

        for (var i = 0; i < text.length; i++) {

            if (text.substr(i, 2) === '**') {

                i = i + 1;

                if (strong === 0) {
                    output = output + "<strong>";
                    strong = 1;
                } else {
                    output = output + "</strong>";
                    strong = 0;
                }

            } else {
                output = output + text.substr(i, 1);
            }
        }

        return output;

    }

    handleClosed = () => {
        if (this.state.shouldPauseVideo) {
            const player = this.youtubeRef.current.internalPlayer;
            if (player) {
                player.stopVideo();
            }
        }
    };

    videoPop() {
        const content = (
            <div id="video-container">
                <YouTube
                    ref={this.youtubeRef}
                    videoId="Lr_cs6jo22g"
                    opts={{
                        playerVars: {
                            autoplay: 0,
                            rel: 0,
                        },
                    }}
                    />
            </div>
        );

        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    text: 'Visitors for Trello',
                },
                fadeIn: true,
                fadeInSpeed: 500,
                overlayStyle: {
                    width: '70%', // Set the desired width here
                },
                onClosed: this.handleClosed,
            },
        });
    }


    render() {

        const colorFront    = {
            color: this.state.colors.colorFront,
            textShadow: '0px 4px 3px ' + this.state.colors.colorFrontShadow + this.state.colors.colorFrontShadowTransparency,
            whiteSpace: 'pre-wrap'
        }

        return (
            <div id="app-window" style={ this.state.background }>

                {this.state.hasAccount === 0 &&
                <div className="centered-div">
                    <img id="free-version-badge" src={FreeVersionBadge} alt="Free version of Visitors for Trello" onClick={() => this.videoPop()}/>
                    <span className="badge-text" style={ colorFront }>Subscribe to remove this Badge</span>
                </div>
                }

                {this.state.hasAccount === 1 && this.state.freeSMSPoints === "50" &&
                <div className="centered-div">
                    <img id="free-sms-trial-badge" src={FreeSMSTrialBadge} alt="Free SMS Trial of Visitors for Trello" onClick={() => this.videoPop()}/>
                    <span className="badge-text" style={ colorFront }>Subscribe to remove this Badge</span>
                </div>
                }

                {this.state.screen === 'splashscreen' && (
                    <SplashScreen
                        colors              = {this.state.colors}
                        background          = {this.state.background}
                        splashLogo          = {this.state.splashLogo}
                        />
                )}
                {this.state.screen === 'home' && (
                    <Home
                        colors              = {this.state.colors}
                        background          = {this.state.background}
                        t                   = {this.t.bind(this)}
                        languages           = {this.state.languages}
                        languageSelect      = {this.state.languageSelect}
                        changeLanguage      = {this.changeLanguage.bind(this)}
                        homeMessage         = {this.state.homeMessage}
                        clearHomeMessage    = {this.clearHomeMessage.bind(this)}
                        loadClientValues    = {this.loadClientValues.bind(this)}
                        step                = {this.state.step}
                        changeScreen        = {this.changeScreen.bind(this)}
                        screen              = {this.state.screen}
                        mainStyle           = {this.state.mainStyle}
                        setStyles           = {this.setStyles.bind(this)}
                        />
                )}
                {this.state.screen === 'check-in-confirm' && (
                    <CheckInConfirm
                        guestName           = {this.state.guestName}
                        colors              = {this.state.colors}
                        background          = {this.state.background}
                        t                   = {this.t.bind(this)}
                        languages           = {this.state.languages}
                        languageSelect      = {this.state.languageSelect}
                        changeLanguage      = {this.changeLanguage.bind(this)}
                        setHomeMessage      = {this.setHomeMessage.bind(this)}
                        terms               = {this.state.terms}
                        languageDefault     = {this.state.languageDefault}
                        updateGuest         = {this.updateGuest.bind(this)}
                        step                = {this.state.step}
                        changeScreen        = {this.changeScreen.bind(this)}
                        screen              = {this.state.screen}
                        mainStyle           = {this.state.mainStyle}
                        setStyles           = {this.setStyles.bind(this)}
                        />
                )}
                {this.state.screen === 'check-out-confirm' && (
                    <CheckOutConfirm
                        guestName           = {this.state.guestName}
                        colors              = {this.state.colors}
                        background          = {this.state.background}
                        t                   = {this.t.bind(this)}
                        languages           = {this.state.languages}
                        languageSelect      = {this.state.languageSelect}
                        changeLanguage      = {this.changeLanguage.bind(this)}
                        setHomeMessage      = {this.setHomeMessage.bind(this)}
                        languageDefault     = {this.state.languageDefault}
                        updateGuest         = {this.updateGuest.bind(this)}
                        step                = {this.state.step}
                        changeScreen        = {this.changeScreen.bind(this)}
                        screen              = {this.state.screen}
                        mainStyle           = {this.state.mainStyle}
                        setStyles           = {this.setStyles.bind(this)}
                        />
                )}
                {this.state.screen === 'error-page' && (
                    <ErrorPage
                        colors              = {this.state.colors}
                        background          = {this.state.background}
                        splashLogo          = {this.state.splashLogo}
                        hostCode            = { this.getParameterByName('host')}
                        loadClientValues    = {this.loadClientValues.bind(this)}
                        t                   = {this.t.bind(this)}
                        languages           = {this.state.languages}
                        languageSelect      = {this.state.languageSelect}
                        changeLanguage      = {this.changeLanguage.bind(this)}
                        />
                )}

                <PopupboxContainer />
            </div>
        );
    }
}

export default App;

